<template>
  <div>
    <div style="min-height: 265px">
      <div v-if="students.length > 0">
        <div class="text-left">Total {{students.length}} students.</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Full Name</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in students" :key="i">
                <td class="text-left">{{ i + 1 }}</td>
                <td class="text-left">{{ item }}</td>
                <td class="text-right">
                  <v-btn @click="remove(i)" color="red" text>
                    Remove
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-else>No names are found.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["callbackSuccess", "students"],
  data: () => ({
   
  }),
  created() {
  },
  methods: {
    remove(key) {
      this.students.splice(key, 1);
      if (this.students.length == 0) {
        this.$emit("close");
      }
    },
   
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>