<template>
	<div>
		<!-- <div class="pb-2 text-left">
		{{$t("view.PageBackendStudent.add_new_1_description")}}<br/>	{{$t("view.PageBackendStudent.add_new_2_description")}}
		</div> -->
		<div class="text-left mb-3">
			<b>Student Names</b><br/>
			One name per row.<br/>
			You may copy and paste directly from Excel.<br/>
		</div>
		<div class="name-textarea">
			<v-textarea
			autofocus
				outlined
				name="input-7-1"
				placeholder="Bruce Lee
P. Ramlee
Mohandas Gandhi
Marie Curie"
				v-model="students"
				:rules="rules"
			></v-textarea>
		</div>
	</div>
</template>

<script>
// import i18n from '@/plugins/i18n'
export default {
	props: ["callbackSelect", "seletedStudents"],
	data: () => ({
		students: "",
		filterStudents: [],
		name: null,
		// rules: [(v) => !!v || i18n.t("rules.name")],
		rules: [(v) => !!v || "Please fill in the names"],
	}),
	watch: {
		students: function (val) {
			this.students = val;
			this.filterStudents = [];
			var filterStudents = [];
			this.name = val.split("\n");
			this.name?.forEach((line) => {
				filterStudents.push(line);
			});
			this.filterStudents = filterStudents;
			this.callbackSelect(this.filterStudents);
		},
	},
	methods: {
	
	},
	mounted() {
		for (var i = 0; i < this.seletedStudents.length; i++) {
				this.students = this.students + this.seletedStudents[i] + "\n";
		}
	},
};
</script>

<style>
.name-textarea {
	-moz-tab-size: 24 !important; /* Firefox */
	tab-size: 24 !important;
}
</style>